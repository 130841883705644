import axios from "axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { PLAYZER_GAME_SERVER_URL } from "@app/config";

import CharactersPlaying from "@app/components/CharactersPlaying";

import { User } from "@app/types/user";
import { Answers, ClassicAnswer, IntruderAnswer, Question, RoundType } from "@app/types/question";

import styles from "@app/assets/styles/game.module.scss";

import { GameImages } from "@app/assets/images/game/game.js";

import Context from "@app/contexts/Context";

export default function Game() {
    const {
        ioSocket,
        setRoomId,
        setIoSocket,
        roomType,
        roomId,
    } = useContext(Context);

    const navigate = useNavigate();
    const [timer, setTimer] = useState<string>("10");
    const [question, setQuestion] = useState<Question | undefined>();
    const [players, setPlayers] = useState<User[]>([]);
    const [answers, setAnswers] = useState<Answers>();
    const [roundType, setRoundType] = useState<RoundType>();

    const timerAudio = new Audio(require("../assets/audios/question-test.mp3"));
    const answerAudio = new Audio(require("../assets/audios/select-answer.mp3"));

    // Queries
    useQuery(["get", "players"], async () => {
            const players: User[] = (await axios.get(`${PLAYZER_GAME_SERVER_URL}/rooms/users/room-${roomId}`)).data;
            setPlayers(players);
        },
        { keepPreviousData: false }
    );

    useEffect(() => {
        function exitRoom() {
            setRoomId(undefined);
            if (ioSocket) {
                ioSocket.disconnect();
            }
            setIoSocket(undefined);
            navigate("/");
        };

        function answers(answers: Answers) {
            timerAudio.pause();
            timerAudio.currentTime = 0;
            console.log("##### answers :", answers);
            setAnswers(answers);
        };

        function newQuestion(question: Question) {
            setAnswers(undefined);
            if (ioSocket) {
                ioSocket.emit("getPlayers");
            }
            setQuestion(question);
            if (question.answers.length > 0) {
                timerAudio.play();
            }
        };

        function gameEnded(winner: User) {
            navigate(`/game-ended/${winner.name}`);
            if (ioSocket) {
                ioSocket.off("timer", timer);
                ioSocket.off("answers", answers);
                ioSocket.off('newRound', newRound);
                ioSocket.off("gameEnded", gameEnded);
                ioSocket.off("deleteRoom", exitRoom);
                ioSocket.off("newQuestion", newQuestion);
            }
        };

        function timer(time: number) {
            setTimer(time.toString());
        };

        function newRound(type: RoundType) {
            setQuestion(undefined);
            setRoundType(type);
        }

        if (ioSocket) {
            ioSocket.on("timer", timer);
            ioSocket.on("answers", answers);
            ioSocket.on('newRound', newRound);
            ioSocket.on("gameEnded", gameEnded);
            ioSocket.on("deleteRoom", exitRoom);
            ioSocket.on("newQuestion", newQuestion);
        }

        return () => {
            if (ioSocket) {
                ioSocket.off("timer", timer);
                ioSocket.off("answers", answers);
                ioSocket.off('newRound', newRound);
                ioSocket.off("gameEnded", gameEnded);
                ioSocket.off("deleteRoom", exitRoom);
                ioSocket.off("newQuestion", newQuestion);
            }
        };
    }, []);
    
    let gridStyles: React.CSSProperties = { gridTemplateColumns: "40% 40%" };

    if (question && question.answers.length > 6) {
        gridStyles = {
            gridTemplateColumns: "25% 25% 25% 25%",
        }
    } else if (question && question.answers.length > 4) {
        gridStyles = {
            gridTemplateColumns: "33% 33% 33%",
        }
    }

    return (
        <div className={styles.gameContainer}>
            <img src={GameImages.background.url} alt=""/>
            {/* <img src={GameImages.resultBackground.url}/> */}
            <div className={styles.wrapper}>
                <div className={styles.charactersWrapper}>
                    <CharactersPlaying
                        players={players}
                        question={question}
                        playersAnswer={answers}
                        roundType={roundType}
                        ioSocket={ioSocket}
                    />
                </div>
                <div className={styles.questionWrapper}>
                    <img className={styles.questionInsert} src={GameImages.question.url} alt=""/>
                    <div className={styles.questionInsertText}>
                        {question?.question}
                    </div>
                    {question && question.answers?.length > 0 && (
                        <div className={styles.grid} style={gridStyles}>
                            {question.answers.map((answerText, answerIndex) => {
                                let color = "#fff";
                                let image = GameImages.answer.url;

                                if (answers) {
                                    if (roundType !== "Intruder") {
                                        const classicPlayersAnswer = answers as ClassicAnswer;
                                        const player = classicPlayersAnswer.usersAnswer[0];

                                        if (classicPlayersAnswer.correctAnswer === answerIndex) {
                                            color = "#000";
                                            image = GameImages.goodAnswer.url;
                                        } else if (roomType === "solo" && player.answer === answerIndex && classicPlayersAnswer.correctAnswer !== player.answer) {
                                            image = GameImages.wrongAnswer.url;
                                        }
                                    } else {
                                        const intruderAnswer = answers as IntruderAnswer;
                                        if (intruderAnswer.userAnswered?.lastAnswer === undefined || (intruderAnswer.userAnswered.lastAnswer === intruderAnswer.wrongAnswer && intruderAnswer.wrongAnswer === answerIndex)) {
                                            image = GameImages.wrongAnswer.url;
                                        } else if (intruderAnswer.userAnswered.lastAnswer === answerIndex) {
                                            color = "#000";
                                            image = GameImages.goodAnswer.url;
                                        }
                                    }
                                }

                                return (
                                    <div key={answerIndex} className={styles.button} style={{ background: `url(${image}) center / contain no-repeat`, color: color }}>
                                        {answerText}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
