export const GameImages = {
    background: {
        url: "../images/game/background.webp",
    },
    resultBackground: {
        url: "../images/game/background_result.webp",
    },
    winBackground: {
        url: "../images/game/background_win.webp",
    },
    question: {
        url: "../images/game/question.webp",
    },
    answer: {
        url: "../images/game/response.webp",
    },
    goodAnswer: {
        url: "../images/game/response_good.webp",
    },
    wrongAnswer: {
        url: "../images/game/response_wrong.webp",
    },
    winInsert: {
        url: "../images/game/encart_win.webp",
    },
    star: {
        url: "../images/game/star.webp",
    },
    lobby: {
        url: "../images/game/lobby.webp",
    }
}