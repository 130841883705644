import { createContext } from 'react';
import { Socket } from 'socket.io-client';

import { RoomType } from '../types/room';

export type ContextType = {
    ioSocket?: Socket;
    setIoSocket: (socket?: Socket) => void;
    roomId?: string;
    setRoomId: (roomId?: string) => void;
    roomType?: RoomType;
    setRoomType: (roomType?: RoomType) => void;
}

const Context = createContext<ContextType>({
    setRoomId: () => {},
    setIoSocket: () => {},
    setRoomType: () => {},
});

export default Context;
