

import { CharacterType } from "@app/types/user";

import { CharacterImages } from "@app/assets/images/character/character";


export type CharacterStatusType = "wrong" | "good" | "answer" | "thinking" | "neutral" | "buzz";
export type CharacterImagesType = {
    wrong: any,
    good: any,
    answer: any,
    thinking: any,
    neutral: any,
    buzz: any,
};

export function getCharacterImages(type?: CharacterType) {
    let characterImages;

    switch (type) {
        case "astronaut":
            characterImages = CharacterImages.astronaut;
            break;
        case "cat":
            characterImages = CharacterImages.cat;
            break;
        case "alien":
            characterImages = CharacterImages.alien;
            break;
        case "catWoman":
            characterImages = CharacterImages.catWoman;
            break;
        case "death":
            characterImages = CharacterImages.death;
            break;
        case "physicist":
            characterImages = CharacterImages.physicist;
            break;
        case "punk":
            characterImages = CharacterImages.punk;
            break;
        case "viking":
            characterImages = CharacterImages.viking;
            break;
    }

    return characterImages;
}

export function getCharacterStatus(statusType?: CharacterStatusType, characterImages?: CharacterImagesType) {
    let characterStatus;

    switch (statusType) {
        case "answer":
            characterStatus = characterImages?.answer;
            break;
        case "buzz":
            characterStatus = characterImages?.buzz;
            break;
        case "good":
            characterStatus = characterImages?.good;
            break;
        case "neutral":
            characterStatus = characterImages?.neutral;
            break;
        case "thinking":
            characterStatus = characterImages?.thinking;
            break;
        case "wrong":
            characterStatus = characterImages?.wrong;
            break;
    }

    return characterStatus;
}