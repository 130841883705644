import axios from "axios";
import { useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CastReceiverContext } from "chromecast-caf-receiver/cast.framework";

import styles from "@app/assets/styles/end-game.module.scss";

import { GameImages } from "@app/assets/images/game/game";
import { CharacterImages } from "@app/assets/images/character/character";

import { User } from "@app/types/user";

import Context from "@app/contexts/Context";

import { PLAYZER_GAME_SERVER_URL } from "@app/config";

const sortByScore = (a: any, b: any) => {
    if (a.score == b.score) {
        return 0;
    }

    return (a.score > b.score) ? -1 : 1;
};

interface EndGameRoutProps {
    receiverContext?: CastReceiverContext;
}

export default function EndGame(props: EndGameRoutProps) {
    const { receiverContext } = props;

    const params = useParams();

    const navigate = useNavigate();
    const [players, setPlayers] = useState<User[]>();

    const {
        ioSocket,
        roomId,
        setRoomId,
        setIoSocket,
    } = useContext(Context);

    let winnerImage;

    if (players) {
        switch (players[0]?.character) {
            case "viking":
                winnerImage = CharacterImages.viking.win;
                break;
            case "punk":
                winnerImage = CharacterImages.punk.win;
                break;
            case "cat":
                winnerImage = CharacterImages.cat.win;
                break;
            case "catWoman":
                winnerImage = CharacterImages.catWoman.win;
                break;
            case "alien":
                winnerImage = CharacterImages.alien.win;
                break;
            case "death":
                winnerImage = CharacterImages.death.win;
                break;
            case "astronaut":
                winnerImage = CharacterImages.astronaut.win;
                break;
            case "physicist":
                winnerImage = CharacterImages.physicist.win;
                break;
        }
    }

    //Query
    useQuery(["get", "players"], async () => {
            const players: User[] = (await axios.get(`${PLAYZER_GAME_SERVER_URL}/rooms/users/room-${roomId}`)).data;
            players.sort(sortByScore);
            setPlayers(players);
        },
        { keepPreviousData: false }
    );

    useEffect(() => {
        function exitRoom() {
            setRoomId(undefined);
            if (ioSocket) {
                ioSocket.disconnect();
            }
            if (receiverContext) {
                receiverContext.stop();
            }
            setIoSocket(undefined);
            navigate("/");
        };

        if (ioSocket) {
            ioSocket.on("deleteRoom", exitRoom);
        }
        return () => {
            if (ioSocket) {
                ioSocket.off("deleteRoom", exitRoom);
            }
        }
    }, [])

    return (
    <div className={styles.endGameContainer}>
        <img style={{top: 0}} src={winnerImage} alt=""/>
        <img src={GameImages.winBackground.url} alt=""/>
        <div className={styles.endGameInfosWrapper}>
            <div className={styles.topWrapper}>
                <div className={styles.winnerWrapper} style={{ background: `url(${GameImages.winInsert.url}) center / contain no-repeat` }}>
                    <div className={styles.firstText}>Félicitations</div>
                    {players && <div className={styles.winnerName}>{players[0].name}</div>}
                    <div className={styles.secondText}>a gagné la partie !</div>
                </div>
                {players && <div className={styles.winnerScore}>Score: {players[0].score}</div>}
            </div>
            <div className={styles.bottomWrapper}>
                <div className={styles.playersWrapper}>
                    {players && players.map((player, index) => {
                            if (index === 0) return;
                            const characterImg = CharacterImages.allCharacter.find((character) => character.type === player.character)?.url;
                            return (
                                <div key={index} className={styles.playerWrapper}>
                                    <img src={characterImg} alt=""/>
                                    <div className={styles.star} style={{ background: `url(${GameImages.star.url}) center / contain no-repeat` }}>{index + 1}</div>
                                    <div className={styles.playerText}>
                                        <div>{player.name}</div>
                                        <div>Score : {player.score}</div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    </div>
    );
}
