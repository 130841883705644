export const CharacterImages = {
  allCharacter: [
      {
        type: "astronaut",
        url: "../images/character/astronaut/square.webp"
      },
      {
        type: "cat",
        url: "../images/character/cat/square.webp"
      },
      {
        type: "catWoman",
        url: "../images/character/catWoman/square.webp"
      },
      {
        type: "punk",
        url: "../images/character/punk/square.webp"
      },
      {
        type: "viking",
        url: "../images/character/viking/square.webp"
      },
      {
        type: "death",
        url: "../images/character/death/square.webp"
      },
      {
        type: "alien",
        url: "../images/character/alien/square.webp"
      },
      {
        type: "physicist",
        url: "../images/character/physicist/square.webp"
      },
  ],
  viking: {
    lobby: "../images/character/viking/lobby.png",
    win: "../images/character/viking/win.png",
    buzz: "../images/character/viking/buzz.png",
    good: "../images/character/viking/good.png",
    wrong: "../images/character/viking/wrong.png",
    neutral: "../images/character/viking/neutral.png",
    answer: "../images/character/viking/answer.png",
    thinking: "../images/character/viking/thinking.png",
  },
  astronaut: {
    lobby: "../images/character/astronaut/lobby.png",
    win: "../images/character/astronaut/win.png",
    buzz: "../images/character/astronaut/buzz.png",
    good: "../images/character/astronaut/good.png",
    wrong: "../images/character/astronaut/wrong.png",
    neutral: "../images/character/astronaut/neutral.png",
    answer: "../images/character/astronaut/answer.png",
    thinking: "../images/character/astronaut/thinking.png",
  },
  catWoman: {
    lobby: "../images/character/catWoman/lobby.png",
    win: "../images/character/catWoman/win.png",
    buzz: "../images/character/catWoman/buzz.png",
    good: "../images/character/catWoman/good.png",
    wrong: "../images/character/catWoman/wrong.png",
    neutral: "../images/character/catWoman/neutral.png",
    answer: "../images/character/catWoman/answer.png",
    thinking: "../images/character/catWoman/thinking.png",
  },
  punk: {
    lobby: "../images/character/punk/lobby.png",
    win: "../images/character/punk/win.png",
    buzz: "../images/character/punk/buzz.png",
    good: "../images/character/punk/good.png",
    wrong: "../images/character/punk/wrong.png",
    neutral: "../images/character/punk/neutral.png",
    answer: "../images/character/punk/answer.png",
    thinking: "../images/character/punk/thinking.png",
  },
  death: {
    lobby: "../images/character/death/lobby.png",
    win: "../images/character/death/win.png",
    buzz: "../images/character/death/buzz.png",
    good: "../images/character/death/good.png",
    wrong: "../images/character/death/wrong.png",
    neutral: "../images/character/death/neutral.png",
    answer: "../images/character/death/answer.png",
    thinking: "../images/character/death/thinking.png",

  },
  alien: {
    lobby: "../images/character/alien/lobby.png",
    win: "../images/character/alien/win.png",
    buzz: "../images/character/alien/buzz.png",
    good: "../images/character/alien/good.png",
    wrong: "../images/character/alien/wrong.png",
    neutral: "../images/character/alien/neutral.png",
    answer: "../images/character/alien/answer.png",
    thinking: "../images/character/alien/thinking.png",

  },
  physicist: {
    lobby: "../images/character/physicist/lobby.png",
    win: "../images/character/physicist/win.png",
    buzz: "../images/character/physicist/buzz.png",
    good: "../images/character/physicist/good.png",
    wrong: "../images/character/physicist/wrong.png",
    neutral: "../images/character/physicist/neutral.png",
    answer: "../images/character/physicist/answer.png",
    thinking: "../images/character/physicist/thinking.png",

  },
   cat: {
    lobby: "../images/character/cat/lobby.png",
    win: "../images/character/cat/win.png",
    buzz: "../images/character/cat/buzz.png",
    good: "../images/character/cat/good.png",
    wrong: "../images/character/cat/wrong.png",
    neutral: "../images/character/cat/neutral.png",
    answer: "../images/character/cat/answer.png",
    thinking: "../images/character/cat/thinking.png",

   }
};
  